<template>
    <div 
        class="container container-indu boxes-services" 
        ref="boxes_services"
        :class="{ 'box-services-no-home': full_top }">
        
        <div class="columns">
            <!-- First -->
            <router-link 
                tag="div" 
                :to="{ name: data_box.box_1.name_link }"
                data-aos="fade-up" 
                data-aos-duration="1000"
                data-aos-delay="600"
                class="column first-column">
                <!-- Text -->
                <div class="text">
                    <h1 class="title">{{ data_box.title }}</h1>
                    <p 
                        v-html="data_box.description"
                        class="description">
                    </p>
                    <div class="text-right">
                        <router-link class="contact-button" tag="button" :to="{ name: 'contact' }">
                            Solicitar cotización
                        </router-link>
                    </div>
                </div>
                <!-- Image -->
                <div
                    :style="`background-image: url(${require(`@/assets/images/${data_box.box_1.url_image}`)})`" 
                    class="bg-image">
                    <h1 class="title-1">
                        <span>{{ data_box.box_1.title }}</span>
                    </h1>
                    <p class="explore">
                        Descubrir
                        <span class="material-icons icon-arrow">arrow_forward</span>
                    </p>
                    <p class="number">01</p>
                    <div class="bg-color"></div>
                </div>
            </router-link>
            <!-- Second -->
            <router-link 
                tag="div" 
                :to="{ name: data_box.box_2.name_link }"
                data-aos="fade-up" 
                data-aos-duration="1000"
                data-aos-delay="300" 
                class="column second-column">
                <!-- Image -->
                <div
                    :style="`background-image: url(${require(`@/assets/images/${data_box.box_2.url_image}`)})`" 
                    class="bg-image">
                    <h1 class="title-1">
                        <span>{{ data_box.box_2.title }}</span>
                    </h1>
                    <p class="explore">
                        Descubrir
                        <span class="material-icons icon-arrow">arrow_forward</span>
                    </p>
                    <p class="number">02</p>
                    <div class="bg-color"></div>
                </div>
            </router-link>
            <!-- Third -->
            <router-link 
                tag="div" 
                :to="{ name: data_box.box_3.name_link }"
                data-aos="fade-up" 
                data-aos-duration="1000"  
                class="column third-column">
                <!-- Image -->
                <div
                    :style="`background-image: url(${require(`@/assets/images/${data_box.box_3.url_image}`)})`" 
                    class="bg-image">
                    <h1 class="title-1">
                        <span>{{ data_box.box_3.title }}</span>
                    </h1>
                    <p class="explore">
                        Descubrir
                        <span class="material-icons icon-arrow">arrow_forward</span>
                    </p>
                    <p class="number">03</p>                    
                    <div class="bg-color"></div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: ["data_box", "full_top"],
    data() {
        return {
            data_box_test: {
                title: '',
                description: '',
                box_1: {
                    title: '',
                    url_image: '',
                    name_link: ''
                },
                box_2: {
                    title: '',
                    url_image: '',
                    name_link: ''
                },
                box_3: {
                    title: '',
                    url_image: '',
                    name_link: ''
                },
            }           
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';
@import '@/assets/css/_mixins.scss';

.box-services-no-home {
    padding: 0px 0px 0px 0px !important;
    .columns {
        .third-column {
            @media (min-width: 1024px) {
                margin-top: -85px !important;
            }
            @media (min-width: 1280px) {
                margin-top: -135px !important;
            }
            @media (min-width: 1900px) {
                margin-top: -145px !important;
            }
        }
        @media (min-width: 1900px) {
            margin-top: 0px !important
        }
    }
}
.boxes-services {
    padding: 35px 0px 35px 0px;
    
    .columns {
        // Column
        .column {
            // border: 1px solid green;
            padding: 10px;
            margin-bottom: 2rem;

            .text {
                .title {
                    font-family: $Poppins-SemiBold;
                    font-size: 2rem;
                    margin-bottom: 1.2rem
                }

                @media (min-width: 1024px) {
                    margin-bottom: 2.5rem
                }
                @media (min-width: 1280px) {
                    .title { font-size: 2.5rem; margin-bottom: 2rem }
                }
                @media (min-width: 1900px) {
                    .title { font-size: 3.5rem; margin-bottom: 3.5rem }
                    .description { margin-bottom: 2rem }
                }
            }

            .bg-image {
                font-family: $Poppins-Bold;
                position: relative;
                height: 350px;
                // background-image: url(~@/assets/images/mobiliario-de-oficina/office.jpg);
                background-size: cover;
                background-position: center center;
                cursor: pointer;
                
                &:hover {
                    .number { color: #b20c0c }
                    .explore {
                        .icon-arrow { color: $main-red-1; background-color: #FFFFFF }
                    }
                    .bg-color { background-color: rgba(236, 67, 69, .8) }
                }

                .title-1 {
                    letter-spacing: 2px;
                    color: #fff;
                    position: absolute;
                    font-size: 1.6rem;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    // left: 38px;
                    left: 10%;
                    top: 5%;
                    @include transform(rotate(90deg));
                    // transform-origin: left top 0;
                    top: 0;
                    transform-origin: left;
                    text-align: right;

                    span {
                        display: inline-block;
                        @include transform(rotate(180deg))
                    }
                    
                    @media (min-width: 760px) {
                        font-size: 2.5rem;
                    }
                }
                .explore {
                    position: absolute;
                    z-index: 2;
                    color: #FFFFFF;
                    letter-spacing: 1px;
                    right: 0;
                    margin-top: 10px;
                    margin-right: 3%;
                    display: flex;
                    align-items: center;

                    .icon-arrow { 
                        background-color: $main-red-1;
                        border-radius: 50%;
                        padding: 4px;
                        font-size: 1.2rem;
                        margin-left: 8px
                    }

                    @media (min-width: 760px) {
                        font-size: 1.5rem;
                        letter-spacing: 2px
                    }
                }
                .number {
                    font-family: $Din-CondensedBold;
                    color: $main-red-1;
                    position: absolute;
                    z-index: 2;
                    font-size: 6rem;
                    right: 0;
                    bottom: 0;
                    margin-right: 2%;
                    line-height: 80%;

                    @media (min-width: 760px) {
                        font-size: 8rem
                    }
                }
                .bg-color {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: rgba(0,0,0, .3);
                    z-index: 1;
                    @include transition(all 0.3s);
                }

                @media (min-width: 760px) {
                    height: 480px;

                    .title-1 { max-width: 450px }
                }

                @media (min-width: 1024px) {
                    .title-1 {
                        font-size: 1.1rem;
                        margin-left: -8px
                    }
                    .explore { font-size: 1rem }
                    .number { font-size: 7rem }
                }

                @media (min-width: 1280px) {
                    .title-1 { font-size: 1.4rem }
                }
                @media (min-width: 1900px) {
                    .title-1 { font-size: 2rem; margin-left: -25px }
                    .explore { 
                        font-size: 1.3rem;
                        .icon-arrow { font-size: 1.5rem; margin-left: 15px }
                    }
                    .number { font-size: 9rem }
                }
            }

            @media (min-width: 760px) {
                width: 85%;
                margin: 0px auto 2rem auto
            }

            @media (min-width: 1024px) {
                width: 33%
            }
        }
        /* First */
        .first-column {
            .contact-button { 
                @extend %generic-pico-button;
                margin-bottom: 2rem;
            }

            @media (min-width: 1024px) {
                margin-top: 3.5rem;
                .bg-image { height: 280px }
                .contact-button { margin-bottom: 0px }
            }
            @media (min-width: 1280px) {
                .bg-image {
                    height: 320px
                }
            }
            @media (min-width: 1900px) {
                .bg-image { height: 480px }
            }
        }
        /* Second */
        .second-column {
            @media (min-width: 1024px) {
                margin-top: 4rem;
                .bg-image { height: 380px }
            }
            @media (min-width: 1280px) {
                .bg-image { height: 420px }
            }
            @media (min-width: 1900px) {
                .bg-image { height: 580px }
            }
        }
        /* Third */
        .third-column {
            @media (min-width: 1024px) {
                margin-top: -45px;
                .bg-image { height: 460px }
            }
            @media (min-width: 1280px) {
                margin-top: -125px !important;
                .bg-image { height: 480px }
            }
            @media (min-width: 1900px) {
                margin-top: -133px !important;
                .bg-image { height: 640px }
            }
        }

        @media (min-width: 1024px) {
            display: flex;
            justify-content: space-between
        }
        @media (min-width: 1900px) {
            margin-top: 5rem
        }
    }
}
</style>