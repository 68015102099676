<template>
    <div class="gallery">
        <div class="contaienr-gallery">
            <!-- Left -->
            <div class="left">
                <!-- Top -->
                <div class="top">
                    <h1 
                        data-aos="fade-right"
                        data-aos-duration="1000"
                        class="title"
                        v-html="gallery_data.title">
                        <!-- {{ gallery_data.title }} -->
                    </h1>
                    <router-link tag="button" :to="{ name: gallery_data.button_link }"
                        data-aos="fade-left"
                        data-aos-duration="1000"
                        class="btn">
                        {{ gallery_data.button_text }}
                    </router-link>
                </div>
                <!-- Gallery -->
                <div 
                    data-aos="fade-right" 
                    data-aos-duration="800"
                    class="gallery">
                    <agile ref="gallery" :dots="false" :navButtons="false">
                        <div 
                            class="slide gallery-slide" 
                            v-for="(slide, index) in gallery_data.slides" 
                            :key="index" 
                            :class="`slide--${index}`"
                            :style="`background-image: url(${require(`@/assets/images/${slide}`)})`">
                            <!-- <img :src="slide" /> -->
                        </div>
                    </agile>
                </div>
            </div>
            <!-- Right -->
            <div class="right"> 
                <div
                    data-aos="fade-down"
                    data-aos-duration="800" 
                    class="top">
                    <p v-html="gallery_data.description">
                        <!--  -->
                    </p>
                </div>
                <div
                    data-aos="fade-up"
                    data-aos-duration="800" 
                    class="controls">
                    <div class="control">
                        <div
                            @click="$refs.gallery.goToPrev()" 
                            class="prev">
                            <span class="material-icons">arrow_back</span>
                        </div>
                        <div
                            @click="$refs.gallery.goToNext()" 
                            class="next">
                            <span class="material-icons">arrow_forward</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { VueAgile } from 'vue-agile'
export default {
    components: {
        agile: VueAgile
    }, 
    props: ["gallery_data"],
    data() {
        return {
            // gallery_data_test: {
            //     title: '',
            //     description: '',
            //     button_text: '',
            //     button_link: '',
            //     slides: [
            //         'mobiliario-de-oficina/bg_random.jpg',
            //         'mobiliario-de-oficina/mobiliario-1.jpg',
            //         'mobiliario-de-oficina/office.jpg',
            //         'mobiliario-de-oficina/office-test.jpg',
            //         'mobiliario-de-oficina/banner_office_furniture.jpg'
            //     ]
            // }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';
@import '@/assets/css/_mixins.scss';

.gallery {
    padding: 0px 0px 0px 0px;

    .contaienr-gallery {
        // Left
        .left {
            .gallery { padding: 0px  }
            .gallery-slide {
                width: 100%;
                height: 300px;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;

                img { width: 100% }
            }

            .top {
                padding: 0px 10px 0px 10px;
                margin-bottom: 2rem;

                .title {
                    font-family: $SourceSansPro-SemiBold;
                    font-size: 1.5rem;
                    margin-bottom: 1rem
                }
                button {
                    @extend %generic-pico-button;
                }
            }


            @media (min-width: 760px) {
                width: 70%;

                .gallery-slide { height: 400px }
            }

            @media (min-width: 1024px) {
                margin-top: 2rem;
                .gallery-slide { height: 460px }
                .top { padding: 0px 45px 0px 45px; }
            }
            @media (min-width: 1200px) {
                margin-top: 0rem;
                width: 70%;
                .top {
                    // border: 1px solid red;
                    margin-bottom: 4rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 4rem;

                    .title { 
                        // font-size: 2rem;
                        position: relative;
                        margin-bottom: 0px;
                        padding-top: 15px;

                        &:before {
                            content: '';
                            width: 60%;
                            height: 2px;
                            position: absolute;
                            top: 0;
                            background-color: $main-red-1;
                        }
                    }
                }
                .gallery-slide { height: 550px }
            }
            @media (min-width: 1900px) {
                width: 75%;

                .top {
                    margin-top: 8rem;

                    .title { font-size: 2rem }
                }
                .gallery-slide { height: 600px }
            }
        }

        // Right
        .right {
            .top {
                display: none;
                background-color: #E4E6E5;
                padding: 25px 15px 25px 15px;

                p { 
                    margin: 0px;
                    line-height: 1.7rem;
                    margin-bottom: 0px
                }
            }

            .controls {
                background-color: #FFFFFF;
                padding: 30px;
                display: flex;
                justify-content: center;
                align-items: center;

                .control {
                    border: 2px solid $main-red-1;
                    border-radius: 50%;
                    width: 130px;
                    height: 130px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    .next, .prev {
                        width: 100%;
                        height: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        @include transition(all 0.5s);
                        
                        span { font-size: 1.8rem; color: $main-red-1 }

                        &:hover {
                            background-color: $main-red-1;
                            span { color: #FFFFFF }
                        }
                    }
                }
            }

            @media (min-width: 760px) {
                width: 30%
            }
            @media (min-width: 1024px) {
                .top {
                    display: block;
                    padding: 30px
                }
            }
            @media (min-width: 1200px) {
                .top {
                    padding: 55px 45px 55px 45px;
                    display: flex;
                    align-items: center;
                    min-height: 470px

                    // p { min-height: 310px }
                }

                .controls {
                    padding: 80px 30px 80px 30px
                }
            }
            @media (min-width: 1900px) {
                width: 25%;

                .top {
                    padding: 100px 75px 100px 75px;
                    min-height: 510px;

                    p { 
                        line-height: 1.8rem
                    }
                        
                }

                .controls {
                    padding: 90px 30px 90px 30px;

                     .control {
                        width: 180px;
                        height: 180px;
                         .next, .prev {
                             span { font-size: 2.4rem }
                         }
                     }
                }
            }
        }

        @media (min-width: 760px) {
            display: flex;
            justify-content: space-between;
            align-items: flex-end
        }
    }
}
</style>