import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Meta from 'vue-meta'

Vue.use(Meta)

// Vue.config.productionTip = false

require('bootstrap')
require('./assets/css/main.scss')

import VueAgile from 'vue-agile'
Vue.use(VueAgile)

AOS.init();

import MainHeaderComponent from './components/HeadersMenu/MainHeaderComponent'
Vue.component('main-header-menu', MainHeaderComponent);

import MainHeaderDesktopComponent from './components/HeadersMenu/MainHeaderDesktopComponent'
Vue.component('main-header-menu-desktop', MainHeaderDesktopComponent);

import FooterComponent from './components/Generics/FooterComponent'
Vue.component('main-footer', FooterComponent)

import GenericBgColorComponent from './components/Generics/GenericBgColorComponent'
Vue.component('generic-bg-color', GenericBgColorComponent);

import BoxesServicesComponent from './components/Generics/BoxesServicesComponent'
Vue.component('boxes-services', BoxesServicesComponent);

import GalleryComponent from './components/Generics/GalleryComponent'
Vue.component('gallery-generic', GalleryComponent);

import StoreProductsComponent from './components/Generics/StoreProductsComponent'
Vue.component('store-product', StoreProductsComponent);


// REEMPLAZAR TODO
String.prototype.replaceAll = function(searchStr, replaceStr) {
  let str = this;

   // no match exists in string?
   if(str.indexOf(searchStr) === -1) {
       // return string
       return str;
   }

   // replace and remove first match, and do another recursirve search/replace
   return (str.replace(searchStr, replaceStr)).replaceAll(searchStr, replaceStr);
}

String.prototype.capitalize = function() {
  return this.replace( /(^|\s)([a-z])/g , function(m,p1,p2){ return p1+p2.toUpperCase(); });
};

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
